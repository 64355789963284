:root {
    --main-font: 'Inter', sans-serif;
    --Base-White: #FFF;
    --Base-black: #000;
    --Brand-Primary-bg: #57007B;
    --Secondary-color: #F76680;
    --Tertiary-color: #FF92AE;
    --Success-color: #66CB9F;
    --Warning-color: #F7936F;
    --Danger-color: #F16063;
    --Info-color: #68DBF2;
    --Brand-dark-bg: #16192C;
    --linearGradient-color: linear-gradient(225deg, #F76680 0%, #57007B 100%);
    --Gray-gray-50: #FAFAFA;
    --Gray-gray-100: #F7FAFC;
    --Gray-gray-200: #EDF2F7;
    --Gray-gray-300: #E2E8F0;
    --Gray-gray-400: #CBD5E0;
    --Gray-gray-500: #A0AEC0;
    --Gray-gray-600: #718096;
    --Gray-gray-700: #4A5568;
    --Gray-gray-800: #2D3748;
    --Gray-gray-900: #1A202C;
    --primary-hover-color: linear-gradient(225deg, #F76680 0%, #57007B 100%);
    --linearGradient-hover-color: #57007B;
    --fs-over-Ex-Large-Text: 45px;
    --fs-over-Large-Text: 35px;
    --fs-Over-Text: 28px;
    --fs-Extra-Large-Text: 18px;
    --fs-Large-Text: 20px;
    --fs-medium-Text: 16px;
    --fs-EX-Small-Text: 15px;
    --fs-Small-Text: 14px;
    --fs-Extra-Small-Text: 12px;
    --fw-Extra-bold-text: 800;
    --fw-Extra-Sbold-Text: 700;
    --fw-Semibold-Text: 600;
    --fw-bold-text: 500;
    --fw-light-Text: 400;
    --fw-Extra-light-Text: 300;
}